<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="beUser_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <beUser-filter></beUser-filter>
      <div class="card">
        <div class="card-body">
          <preloader v-if="callingAPI"></preloader>
          <data-table
            :data="beUsers"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '../../components/preloader/Preloader'
import BeUserFilter from '../../components/filter/FilterBeUser'
import DataTable from '../../components/shared/DataTable'
import ButtonCreate from '../../components/shared/ButtonCreate'

export default {
  name: 'BeUserListView',
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('beUser.id'),
          username: this.$t('beUser.username'),
          email: this.$t('beUser.email'),
          enabled: this.$t('beUser.enabled'),
          department: this.$t('beUser.department'),
          lastLogin: this.$t('beUser.last_login')
        },
        actions: {
          edit: 'beUser_edit'
        },
        render: {
          enabled: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          department: departmentId => {
            const dept = this.$store.getters['department/getById'](departmentId)
            if (dept) {
              return dept.name
            }

            return ''
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    beUsers () {
      return this.$store.getters['beUser/list']
    },
    totalCount () {
      return this.$store.getters['beUser/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['beUser/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    Preloader: Preloader,
    beUserFilter: BeUserFilter,
    DataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getBeUsers () {
      this.$store.dispatch('beUser/fetch')
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('beUser/setPage', page)
      this.getBeUsers()
    }
  },
  mounted () {
    this.page = this.$store.getters['beUser/page']
    this.getBeUsers()
  }
}
</script>

<style lang="scss" scoped>

</style>
