<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterBeUsers">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.username" id="filter_username" :label="$t('beUser.username')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.email" id="filter_email" :label="$t('beUser.email')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.firstName" id="filter_firstName" :label="$t('beUser.firstName')"></app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.lastName" id="filter_lastName" :label="$t('beUser.lastName')"></app-input>
            </div>
            <div class="col-lg-2">
              <app-select
                v-model="filter.enabled"
                :options="enabledOptions"
                :label="$t('beUser.enabled')"
                id="filter_enabled"
                noEmptyValue
              >
              </app-select>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30" @click.prevent="filterBeUsers">
                {{ $t('beUser.filter.search') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" data-test="beUser_btn_show_advanced_filter"
                      @click.prevent="toggleAdvancedFilter">
                <i class="fas fa-angle-down" v-if="advancedFilter === false"></i>
                <i class="fas fa-angle-up" v-if="advancedFilter"></i>
              </button>
              <button class="btn btn-success pull-right m-t-30" data-test="beUser_reset_filter"
                      @click.prevent="resetFilters">
                {{ $t('beUser.filter.reset_filter') }}
              </button>
            </div>
          </div>
          <div class="row m-t-20" v-if="advancedFilter">
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtFrom"
                id="filter_createdAtFrom"
                :label="$t('beUser.created_at_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.createdAtTo"
                id="filter_createdAtTo"
                :label="$t('beUser.created_at_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2">
              <app-datepicker
                type="date"
                v-model="filter.lastLoginFrom"
                id="filter_lastLoginFrom"
                :label="$t('beUser.last_login_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2">
              <app-datepicker
                type="date"
                v-model="filter.lastLoginToAtTo"
                id="filter_lastLoginToAtTo"
                :label="$t('beUser.last_login_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2">
              <app-input v-model="filter.id" id="filter_id" :label="$t('beUser.id')"></app-input>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import Datepicker from '@/components/form/Datepicker'
import BeUserFilterModel from '@/model/beUser/BeUserFilter'

export default {
  name: 'BeUserFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(BeUserFilterModel),
      enabledOptions: [
        {
          id: 'none',
          title: '---'
        },
        {
          id: 1,
          title: this.$t('beUser.yes')
        },
        {
          id: 0,
          title: this.$t('beUser.no')
        }
      ],
      advancedFilter: false
    }
  },
  components: {
    appInput: Input,
    appSelect: Select,
    appDatepicker: Datepicker
  },
  methods: {
    filterBeUsers () {
      this.$store.commit('beUser/setPage', 1)
      this.$store.commit('beUser/setFilter', this.filter)
      this.$store.dispatch('beUser/fetch')
    },
    toggleAdvancedFilter () {
      this.advancedFilter = !this.advancedFilter
    },
    resetFilters () {
      this.filter = this._.cloneDeep(BeUserFilterModel)
      this.filterBeUsers()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['beUser/filter']
  }
}
</script>

<style lang="scss">

</style>
